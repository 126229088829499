<template>
    <main>
        <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>

        <Divider />

        <Form ref="form" :model="form" :rules="validate" label-position="top" v-if="model">
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem label="用户名">
                        <p>{{ model.username }}</p>
                    </FormItem>
                    <FormItem label="创建时间">
                        <p>{{ model.created_at }}</p>
                    </FormItem>
                    <FormItem label="登录时间">
                        <p>{{ model.logged_at }}</p>
                    </FormItem>
                    <template v-if="!show_password">
                        <FormItem>
                            <Button icon="md-create" long @click="resetPassword">修改密码</Button>
                        </FormItem>
                    </template>
                    <template v-if="show_password">
                        <FormItem label="密码" prop="password">
                            <Input type="password" v-model="form.password" @on-enter="submit"/>
                        </FormItem>
                        <FormItem label="确认密码" prop="passworded">
                            <Input type="password" v-model="form.passworded" @on-enter="submit"/>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" icon="md-filing" @click="submit">保存修改</Button>
                        </FormItem>
                    </template>
                </Col>
            </Row>
        </Form>
    </main>
</template>

<script>
    export default {
        data()
        {
            return {
                model : null,
                form : {
                    password   : '',
                    passworded : ''
                },
                validate: {
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ],
                    passworded: [
                        { required: true, message: '请再次输入密码', trigger: 'blur' }
                    ]
                },
                show_password: false
            }
        },
        created()
        {
            this.loadData()
        },
        methods: {
            loadData()
            {
                this.$http.get('/profile/detail')
                    .then(res => {
                        this.model = res
                    })
            },
            submit()
            {
                this.$refs.form.validate((valid) => {
                    if (valid)
                    {
                        if (this.show_password && this.form.password != this.form.passworded)
                            return this.$Message.warning('两次密码不一致')

                        this.$http.post('/profile/update', this.form)
                            .then(res => {
                                this.$store.dispatch('logout')
                                this.$router.replace('/login')
                            })
                    }
                })
            },
            resetPassword()
            {
                this.form.password   = ''
                this.form.passworded = ''
                this.show_password   = true
            }
        }
    }
</script>